import { IconName } from "@fortawesome/fontawesome-svg-core";

export type AlertSeverity = 'info' | 'success' | 'warning' | 'danger';

export const ALERT_STATE = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  danger: 'danger'
} as const;

export interface ToastConfig {
  severity: AlertSeverity;
  title: string;
  description: string;
  expanded: boolean;
  displayTime: number;
}

export const ALERT_ICONS = {
  success: 'check-circle' as IconName,
  warning: 'exclamation-triangle' as IconName,
  danger: 'exclamation-circle' as IconName,
  info: 'info-circle' as IconName,
};
