import { computed, Injectable, signal } from '@angular/core';
import { ALERT_STATE, ToastConfig } from '../types/alert.types';

const DEFAULT_DISPLAY_TIME = 5000;

const DEFAULT_CONFIG: ToastConfig = {
  severity: ALERT_STATE.info,
  title: '',
  description: '',
  expanded: false,
  displayTime: DEFAULT_DISPLAY_TIME,
} as const;

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private readonly state = signal<ToastConfig>(DEFAULT_CONFIG);
  private readonly displayedSignal = signal<boolean>(false);
  private timeoutRef?: ReturnType<typeof setTimeout>;

  // Read-only signals
  public readonly alert = this.state.asReadonly();
  public readonly isDisplayed = this.displayedSignal.asReadonly();

  // Computed values
  public readonly severity = computed(() => this.state().severity);
  public readonly title = computed(() => this.state().title);
  public readonly description = computed(() => this.state().description);
  public readonly expanded = computed(() => this.state().expanded);

  show(config: Partial<ToastConfig>): void {
    this.clearTimeout();

    const newConfig = {
      ...DEFAULT_CONFIG,
      ...config,
    };

    this.state.set(newConfig);
    this.displayedSignal.set(true);

    // On vérifie explicitement si displayTime n'est pas undefined et est > 0
    if (config.displayTime !== undefined && config.displayTime > 0) {
      this.setHideTimeout(config.displayTime);
    } else if (config.displayTime === undefined && DEFAULT_CONFIG.displayTime > 0) {
      this.setHideTimeout(DEFAULT_CONFIG.displayTime);
    }
  }

  showInfo(title: string, description: string | undefined, expanded: boolean | undefined, displayTime: number | undefined): void {
    this.show({
      severity: ALERT_STATE.info,
      title,
      description,
      expanded,
      displayTime,
    });
  }

  showSuccess(title: string, description: string | undefined, expanded: boolean | undefined, displayTime: number | undefined): void {
    this.show({
      severity: ALERT_STATE.success,
      title,
      description,
      expanded,
      displayTime,
    });
  }

  showWarning(title: string, description: string | undefined, expanded: boolean | undefined, displayTime: number | undefined): void {
    this.show({
      severity: ALERT_STATE.warning,
      title,
      description,
      expanded,
      displayTime,
    });
  }

  showDanger(title: string, description: string | undefined, expanded: boolean | undefined, displayTime: number | undefined): void {
    this.show({
      severity: ALERT_STATE.danger,
      title,
      description,
      expanded,
      displayTime,
    });
  }

  hide(): void {
    this.clearTimeout();
    this.displayedSignal.set(false);
    this.state.set(DEFAULT_CONFIG);
  }

  toggleExpand(): void {
    this.state.update((state) => ({
      ...state,
      expanded: !state.expanded,
    }));
  }

  private setHideTimeout(displayTime: number): void {
    this.timeoutRef = setTimeout(() => {
      this.displayedSignal.set(false);
      this.timeoutRef = undefined;
    }, displayTime);
  }

  private handleAutoHide(displayTime: number): void {
    if (displayTime > 0) {
      this.setHideTimeout(displayTime);
    }
  }

  private clearTimeout(): void {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = undefined;
    }
  }
}
