import { DestroyRef, Injectable, WritableSignal, computed, inject, signal } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserRole } from '@app/core/models/enums/user-role.enum';
import { type Customer } from '@app/core/models/viewModels/customer';
import { environment } from '@environments/environment';
import { RequestableFoSplInventParametersDto } from '@app/core/models/dto/fo/fo.splInventParameter.dto';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FoInventParameterService } from '../finops/client-api/fo-invent-parameter.service';
import { LoggerService } from '@services/log/logger.service';
import { KeycloakProfile } from 'keycloak-js';
import { User } from '@app/core/models/viewModels/user';
import { applyMapping, createMappings, decodeString } from '@app/shared/utils/parsers/parser';
import { CacheKeys, StorageService, StorageType } from '../storage/storage.service';
import { OrderStatus } from '@app/core/models/enums/order-status.enum';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  destroyRef: DestroyRef = inject(DestroyRef);
  private storageService = inject(StorageService);
  private readonly logger: LoggerService = inject(LoggerService);

  private readonly editmode = new BehaviorSubject<boolean>(false);
  private readonly warehouseParamsSubject = new BehaviorSubject<RequestableFoSplInventParametersDto>(
    {} as RequestableFoSplInventParametersDto,
  );
  private readonly orderFilterStatus = new BehaviorSubject<string[]>([]);

  private readonly userProfileSubject = new BehaviorSubject<User>({
    person: {
      firstName: '',
      lastName: '',
      ref: '',
      contact: {
        mail: '',
        phone: '',
      },
    },
    registerNumber: '',
    defaultDataAreaId: '',
    role: UserRole.NONE,
    language: '',
    customerProfiles: [],
    alias: '',
    logisticEmployeeId: 0,
  } as User);

  private readonly useableCustomer = new BehaviorSubject<Customer[]>([]);



  constructor() {
    this.destroyRef.onDestroy(() => {
      this.setDraftMode(false);
    });
  }

  // #region Suspicious list
  private readonly wannaSuspiciousListSignal = signal<boolean>(false);
  readonly wannaSuspiciousList = computed(() => this.wannaSuspiciousListSignal());

  setWannaSuspiciousList(value: boolean) {
    this.wannaSuspiciousListSignal.set(value);
  }
  // #endregion Suspicious list


  setOrderFilterStatus(value: string[]) {
    this.orderFilterStatus.next(value);
  }

  getOrderFilterStatus() {
    return this.orderFilterStatus.value;
  }

  getAllFiltersSatus(): string[] {
    const filters: string[] = Object.values(OrderStatus).filter((e) => e != OrderStatus.UNKNOWN);
    return filters;
  }

  isNotCustomer() {
    return this.userProfileSubject.value.role == UserRole.ADMIN || this.userProfileSubject.value.role == UserRole.LOGISTIC;
  }

  isAdmin() {
    return this.userProfileSubject.value.role == UserRole.ADMIN;
  }

  isLogisticien() {
    return this.userProfileSubject.value.role == UserRole.LOGISTIC;
  }


  isCustomer() {
    return this.userProfileSubject.value.role == UserRole.USER || this.userProfileSubject.value.role == UserRole.EARLY;
  }
  //#region PageName
  private pageName = signal<string>('');
  setPageName(title: string): void {
    this.pageName.set(title);
  }
  getPageName(): WritableSignal<string> {
    return this.pageName;
  }
  //#endregion PageName


  //#region User

  setUser(user: User): void {
    /*We may need to store the user and preferences tomorrow.*/
    if (user.customerProfiles && user.customerProfiles.length > 0)
      this.storageService.setItem<Customer[]>(StorageType.SESSION, CacheKeys.USER_CUST, user.customerProfiles);
    this.userProfileSubject.next(user);
  }
  getUser(): User {
    return this.userProfileSubject.value;
  }

  ///////////////
  setRegisterNumber(registerNumber: string) {
    this.userProfileSubject.value.registerNumber = registerNumber;
    this.userProfileSubject.next(this.userProfileSubject.value);
  }
  setUserPersonRef(ref: string) {
    this.userProfileSubject.value.person.ref = ref;
    this.userProfileSubject.next(this.userProfileSubject.value);
  }
  setUserDefaultDataAreaId(defaultDataAreaId: string) {
    this.userProfileSubject.value.defaultDataAreaId = defaultDataAreaId;
    this.userProfileSubject.next(this.userProfileSubject.value);
  }
  setUserCustomerProfiles(customerProfiles: Customer[]): void {
    this.storageService.setItem<Customer[]>(StorageType.SESSION, CacheKeys.USER_CUST, customerProfiles);
    this.userProfileSubject.value.customerProfiles = customerProfiles;
    this.userProfileSubject.next(this.userProfileSubject.value);
  }

  setUsablesCustomers(clientOK: Customer[]) {
    this.storageService.setItem<Customer[]>(StorageType.SESSION, CacheKeys.USEABLE_CUST, clientOK);
    this.useableCustomer.next(clientOK);
  }

  getUseableCustomers() {
    if (this.useableCustomer && this.useableCustomer.value && this.useableCustomer.value.length > 0)
      return this.useableCustomer.value;
    return this.retrieveFromStorage<Customer[]>(StorageType.SESSION, CacheKeys.USEABLE_CUST);
  }

  /**
   * @Used for combine Catalogs or orderHeaders Obs
   * @returns
   */
  getUserCustomerProfilesObs(): Observable<Customer[]> {
    return of(this.getStoredOrCachedCustomerProfiles());
  }
  /**
   *  @Used for retrieve Customers of the user
   * @default returns an empty array
   * @returns Customer[] of the user (1 or more)
   */
  getUserCustomersProfiles(): Customer[] {
    return this.getStoredOrCachedCustomerProfiles();
  }

  private getStoredOrCachedCustomerProfiles(): Customer[] {
    /* Check if the cache exists first; if not, look for the stored value in session storage */
    if (
      this.userProfileSubject &&
      this.userProfileSubject.value &&
      this.userProfileSubject.value.customerProfiles &&
      this.userProfileSubject.value.customerProfiles?.length > 0
    ) {
      return this.userProfileSubject.value.customerProfiles;
    }

    return this.retrieveFromStorage<Customer[]>(StorageType.SESSION, CacheKeys.USER_CUST);
  }

  retrieveFromStorage<T>(type: StorageType, key: CacheKeys): T {
    if (this.storageService.haveItem(type, key)) {
      const value = this.storageService.getItem<T>(type, key);
      if (value) return value;
    }
    return {} as T;
  }

  getFirstCustomerProfile(): Observable<Customer> {
    return of(this.getStoredOrCachedCustomerProfiles()[0]);
  }
  setUserRoles(userRole: string[]): void {
    this.userProfileSubject.value.role = this.getTopUserRole(userRole);
    this.userProfileSubject.next(this.userProfileSubject.value);
  }
  getUserRole(): string {
    return this.userProfileSubject.value.role;
  }

  private getTopUserRole(userRole: string[]): UserRole {
    let highestRole: UserRole = UserRole.NONE;

    userRole.forEach((role: string) => {
      switch (role) {
        case environment.keycloak.roles.admin:
          highestRole = UserRole.ADMIN;
          break;
        case environment.keycloak.roles.logistician:
          if (highestRole !== UserRole.ADMIN) {
            highestRole = UserRole.LOGISTIC;
          }
          break;
        case environment.keycloak.roles.user:
          if (highestRole === UserRole.NONE) {
            highestRole = UserRole.USER;
          }
          break;
        case environment.keycloak.roles.earlyAccess:
          if (highestRole === UserRole.NONE) {
            highestRole = UserRole.EARLY;
          }
          break;
        default:
          break;
      }
    });

    return highestRole;
  }

  setUserLang(userLang: string): void {
    this.userProfileSubject.value.language = userLang;
    this.userProfileSubject.next(this.userProfileSubject.value);
  }

  getUserLang$(): Observable<string> {
    return of(this.userProfileSubject.value.language);
  }
  getUserLang(): string {
    return this.userProfileSubject.value.language;
  }
  setUserProfilesFromKc(kcProfile: KeycloakProfile) {
    if (kcProfile != null) {
      if (kcProfile.firstName != null) this.userProfileSubject.value.person.firstName = kcProfile.firstName;
      if (kcProfile.lastName != null) this.userProfileSubject.value.person.lastName = kcProfile.lastName;
      if (kcProfile.email != null) this.userProfileSubject.value.person.contact.mail = kcProfile.email;
      // check if it really need or delete here & fields in interface & binding in the mappers
      if (kcProfile.username != null) this.userProfileSubject.value.alias = kcProfile.username;

      this.userProfileSubject.next(this.userProfileSubject.value);
    }
  }
  setUserLogisticId(logisticEmployeeId: number) {
    this.userProfileSubject.value.logisticEmployeeId = logisticEmployeeId;
    this.userProfileSubject.next(this.userProfileSubject.value);
  }
  getUserLogisticId(): number {
    return this.userProfileSubject.value.logisticEmployeeId;
  }

  resetUserAndCustomerInfo() {
    this.userProfileSubject.next({} as User);
  }
  //#endregion User

  getEditMode(): Observable<boolean> {
    return this.editmode.asObservable();
  }

  isOnEdtion(): boolean {
    return this.editmode.value;
  }

  setEditMode(editmode: boolean): void {
    this.editmode.next(editmode);
  }

  setWarehouseParams(value: RequestableFoSplInventParametersDto) {
    this.warehouseParamsSubject.next(value);
  }
  getWareHouseParams(): RequestableFoSplInventParametersDto {
    return this.warehouseParamsSubject.getValue();
  }

  private readonly foInventParameterService: FoInventParameterService = inject(FoInventParameterService);

  public initWareHouseParams() {
    if (this.isWarehouseParamsEmpty()) {
      const wareHouseParams$ = this.foInventParameterService.getSomeInventParamtersByDataAreaId(
        environment.fo.supplierCompany,
      );
      wareHouseParams$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
        this.setWarehouseParams(params.value[0]);
        this.logger.displayObjectDebug(this.getWareHouseParams(), 'wareHouseParams initWareHouseParams');
      });
    }
  }

  private isWarehouseParamsEmpty(): boolean {
    const params = this.warehouseParamsSubject.getValue();
    return !params || !params.SPLInventLocationId || !params.SPLInventSiteId || !params.dataAreaId;
  }

  getPrnLink(): string {
    const k = decodeString(environment.prn.s, environment.fo.domain);
    const v = decodeString(environment.prn.e, environment.fo.domain);
    const mappings = createMappings(k, v);
    const prnkey = applyMapping(this.getUser().person.contact.mail, mappings);
    return `${environment.prn.baseHref}${this.getUserLang()}${environment.prn.authSegemnt}${prnkey}`;
  }

  /**
   * @param allowedRoles List of authorized roles
   * @returns boolean depending if the user's role is in the list of authorized roles
   */
  hasAccessFromRoles(allowedRoles: string[] | undefined): boolean {
    const userRole = this.getUserRole();
    if (allowedRoles?.includes(userRole) || allowedRoles?.length === 0) return true;
    return false;
  }

  //#region DraftMode
  /* Draft mode */
  private draftMode = signal(false);
  /**
   ** Draft mode **
   * @description Set the draft mode that will be used to prevent
   * the user from leaving the page without saving the changes.
   */
  setDraftMode(value: boolean): void {
    this.draftMode.set(value);
  }
  getDraftMode(): boolean {
    return this.draftMode();
  }
  //#endregion DraftMode
}
