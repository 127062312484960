export const StringValidator = {
  /**
   * Checks if a string is null, empty, or consists only of whitespace.
   * @param value The string to be checked.
   * @returns True if the string is null, undefined, or empty after trimming whitespace; false otherwise.
   */
  isNullOrEmptyString: function(value: string | null | undefined): boolean {
    return value === null || value === undefined || value.trim() === '';
  },

  /**
   * Checks if the length of a string is within a specified range.
   * @param value The string to be checked.
   * @param minLength The minimum length of the string.
   * @param maxLength The maximum length of the string.
   * @returns True if the string's length is between minLength and maxLength, inclusive; false otherwise.
   */
  isValidLength: function(value: string, minLength: number, maxLength: number): boolean {
    const length = value.trim().length;
    return length >= minLength && length <= maxLength;
  },

  /**
   * Checks if a string contains only letters.
   * @param value The string to be checked.
   * @returns True if the string contains only letters; false otherwise.
   */
  containsOnlyLetters: function(value: string): boolean {
    return /^[a-zA-Z]+$/.test(value);
  },

  /**
   * Checks if a string contains only digits.
   * @param value The string to be checked.
   * @returns True if the string contains only digits; false otherwise.
   */
  containsOnlyDigits: function(value: string): boolean {
    return /^\d+$/.test(value);
  },

  /**
   * Checks if a string is alphanumeric (contains only letters and digits).
   * @param str The string to be checked.
   * @returns True if the string contains only letters and digits; false otherwise.
   */
  isAlphaNumeric: function(str: string): boolean {
    const regex = /^[a-zA-Z0-9]*$/;
    return regex.test(str);
  },

  /**
   * Checks if a string is a valid email address.
   * @param value The string to be checked.
   * @returns True if the string is a valid email address; false otherwise.
   */
  isEmail: function(value: string): boolean {
    // Simple regular expression for validating an email address
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  },

  /**
   * Checks if a string is a valid URL.
   * @param url The URL string to be checked.
   * @returns True if the string is a valid URL; false otherwise.
   */
  isValidUrl: function(url: string): boolean {
    const regex = /^(https?:\/\/)?([a-zA-Z\d-]+(\.[a-zA-Z\d-]+)*)\.([a-zA-Z]{2,6})(\/[^\s]*)?$/;
    return regex.test(url);
  },

  /**
   * Checks if a string is a valid phone number.
   * @param phoneNumber The phone number string to be checked.
   * @returns True if the string is a valid phone number in E.164 format; false otherwise.
   */
  isValidPhoneNumber: function(phoneNumber: string): boolean {
    const regex = /^\+?[1-9]\d{1,14}$/; // E.164 format
    return regex.test(phoneNumber);
  },

  /**
   * Checks if a postal code is valid for a specified country.
   * @param postalCode The postal code to be checked.
   * @param isoCountryCode The ISO country code to determine the postal code format.
   * @returns True if the postal code matches the format for the given country; false otherwise.
   */
  isValidPostalCode: function(postalCode: string, isoCountryCode: string): boolean {
    const regexes: Record<string, RegExp> = {
      FR: /^\d{5}$/, // France
      BE: /^\d{4}$/, // Belgium
      DE: /^\d{5}$/, // Germany
      IT: /^\d{5}$/, // Italy
      ES: /^\d{5}$/, // Spain
      JP: /^\d{3}-\d{4}$/, // Japan
      MX: /^\d{5}$/, // Mexico
      MA: /^\d{5}$/, // Morocco
      GB: /^[A-Z]{1,2}\d{1,2}[A-Z]?\s?\d[A-Z]{2}$/, // United Kingdom
      US: /^\d{5}(-\d{4})?$/, // United States
      CA: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, // Canada
      KR: /^\d{5}$/, // South Korea
      KP: /^\d{6}$/, // North Korea
      RU: /^\d{6}$/ // Russia
      // Add more countries as needed
    };

    const regex = regexes[isoCountryCode.toUpperCase()];
    return regex ? regex.test(postalCode) : false;
  },

  /**
   * Gets the country code based on the language code.
   * @param languageCode The language code to be mapped to a country code.
   * @returns The ISO country code corresponding to the language code, or null if not found.
   */
  getCountryCodeByLanguage: function(languageCode: string): string | null {
    const mapping: Record<string, string> = {
      fr: 'FR', // French -> France
      nl: 'BE', // Dutch -> Belgium
      de: 'DE', // German -> Germany
      it: 'IT', // Italian -> Italy
      es: 'ES', // Spanish -> Spain
      ja: 'JP', // Japanese -> Japan
      'es-MX': 'MX', // Spanish (Mexico) -> Mexico
      'ar-MA': 'MA', // Arabic (Morocco) -> Morocco
      'en-GB': 'GB', // English (United Kingdom) -> United Kingdom
      'en-US': 'US', // English (United States) -> United States
      'en-CA': 'CA', // English (Canada) -> Canada
      ko: 'KR', // Korean -> South Korea
      'ko-KP': 'KP', // Korean (North Korea) -> North Korea
      ru: 'RU' // Russian -> Russia
      // Add more mappings as needed
    };

    // Extract potential country code
    const potentialCountryCode = languageCode.includes('-') ? languageCode.split('-')[1] : languageCode;

    // Check if the potential country code is in the mapping
    return mapping[potentialCountryCode.toLowerCase()] || null;
  },

  /**
   * Validates a string input based on minimum and maximum length constraints.
   * @param input The string input to be validated.
   * @param minLength The minimum length of the string.
   * @param maxLength The maximum length of the string.
   * @returns True if the string is not null or empty and its length is within the specified range; false otherwise.
   */
  validateInput: function(input: string, minLength: number = 3, maxLength: number = 20): boolean {
    if (this.isNullOrEmptyString(input)) {
      console.error('The string must not be empty.');
      return false;
    }

    if (!this.isValidLength(input, minLength, maxLength)) {
      console.error('The string length must be between 3 and 20 characters.');
      return false;
    }

    return true;
  },

  haveValue(searchString: string, expected:string){
    return searchString.toLowerCase().indexOf(expected.toLowerCase()) > -1;
  }
};
