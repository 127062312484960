import { LogLevel } from '@app/core/models/enums/log-level.enum';
import { provideStoreDevtools } from '@ngrx/store-devtools';

export const environment = {

  name: 'sandbox',
  production: false,
  logLevel: LogLevel.Info,
  enableQueryDebug: false,
  inMemory: false,
  useMailling : true,
  keycloak: {
    initconfig: {
      url: 'https://preprod.kc.cotral.com/auth',
      realm: 'Cotral',
      clientId: 'LogiLab'
    },
    roles:{
      user: 'logilab-user',
      earlyAccess: 'logilab-early-access',
      admin: 'logilab-admin',
      logistician: 'logilab-logistician'
    },
    scope: 'openid profile email roles web-origins',
    idpHint: 'azure-ad',
    // grantType: 'authorization_code',
    flow: 'standard'
  },
  gateway: {
    url: 'https://esb.sandbox.cotral.com/gateway/',
    segments:{
      mail: 'SendMail',
      request:'Requests',
      sendBatch:'sendbatch'
    }
  },
  mail: {
    sender:'noreply@cotral.com',
    recipient:['84f49419.cotral.com@fr.teams.ms','achats-logistique@cotral.com']
  },
  fo: {
    domain: 'cotral-sandboxde2869e64d9ae75cdevaos.axcloud.dynamics.com',
    batchId: 'request_dynamics365',
    referencingCategoryName: 'LogiLab',//referencinCategory & SalesOrigin
    ecoResCategoryHierarchyName: 'Application scopes',
    defaultLocalPickUp : 'LOCAL CUST',
    supplierCompany: 'CPRO',
    orderNumberCreationDefaultValue: 'ToGenerate',
    suppItems: []
  },
  translation: {
    supportedLanguages: ['de', 'en', 'es', 'fr', 'it'],
    defaultLanguage: 'en'
  },
  providers: [
    provideStoreDevtools({ maxAge: 25, logOnly: false, autoPause: true })
  ],
  prn:{
    enabled: false,
    s:"47b832dbc6b6665414fe8d3939009b94b44db0d8af7f763dec668f615cda1edf2Yxdz+ASWzJkOri/oW3UBM7Y+U0UrY+57Umdp8zSM/8NgX1pJDlcbGcJi/wpPe9h",
    e:"032ffbc20a53cbdd814c204b8d837545c985767937eadacfb20897456def9c1bejZLp+WrbMDSSQ52HkbexJLvDgIyNOdtNoPgSxJ1fd+ceUH4D0ahmtZ6OG3GqUVB",
    // baseHref:"https://cotral.prn.",
    baseHref:"https://cotral.prn.FAILED",
    authSegemnt:"/auth/auth.php?p="
  },
  param:{
    order:{
      maxCustOpenOrder : 1
    },
    article :{
      filterHasNoBom: true // if true filtered product without BOM (no subProducts) else apply no filters
    }
  }
};
